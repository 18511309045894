<template>
    <v-dialog
        v-model="dialog"
        width="600"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
        style="background-color: blue; border-radius: 30px !important"
    >
        <v-form v-model="valid">
            <v-card flat style="overflow: hidden">
                <v-card-title class="secondary py-1" style="height: 40px">
                    <span class="dialog-title">Staff Details</span>
                    <v-spacer />
                    <v-icon small color="primary" @click="$emit('close')"
                        >mdi-window-close</v-icon
                    >
                </v-card-title>
                <v-card-text>
                    <v-row class="pl-6 pt-6 pb-0">
                        <v-col class="col-left-alignment">
                            <span style="font-size: 18px">Personal details</span>
                        </v-col>
                    </v-row>
                    <v-row dense class="px-6 pt-0 pb-2">
                        <v-col>
                            <v-text-field
                                v-model="user.firstName"
                                label="Name *"
                                prepend-inner-icon="mdi-account mr-1"
                                dense
                                background-color="#E8E8E8"
                                rounded
                                outlined
                                style="border-radius: 12px !important; height: 40px"
                                :rules="[(rule) => !!rule || '']"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="user.lastName"
                                label="Surname *"
                                prepend-inner-icon="mdi-account mr-1"
                                dense
                                background-color="#E8E8E8"
                                rounded
                                outlined
                                style="border-radius: 12px !important; height: 40px"
                                :rules="[(rule) => !!rule || '']"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="px-6 pb-2">
                        <v-col>
                            <validation-provider rules="required|email" :name="$t('email')" v-slot="{ errors }">
                                <v-text-field
                                    v-model="user.email"
                                    label="Email *"
                                    prepend-inner-icon="mdi-email mr-1"
                                    dense
                                    background-color="#E8E8E8"
                                    rounded
                                    outlined
                                    style="border-radius: 12px !important; height: 40px"
                                    :error-messages="emailError = errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <v-text-field
                                    ref="phoneNumber"
                                    v-model="user.phone"
                                    validate-on-blur
                                    label="Phone number *"
                                    prepend-inner-icon="mdi-cellphone mr-4"
                                    dense
                                    background-color="#E8E8E8"
                                    rounded
                                    outlined
                                    style="border-radius: 12px !important; height: 40px"
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row class="pl-6 pt-0 pb-0">
                        <v-col class="col-left-alignment">
                            <span style="font-size: 18px">Address</span>
                        </v-col>
                    </v-row>
                    <v-row dense class="px-6 pb-2">
                        <v-col>
                            <v-text-field
                                ref="address"
                                @blur="locationDetails()"
                                v-model="user.address"
                                validate-on-blur
                                label="Address, State, Postal Code, City, Country *"
                                prepend-inner-icon="mdi-map-marker mr-4"
                                dense
                                background-color="#E8E8E8"
                                rounded
                                outlined
                                placeholder="Address, State, Postal Code, City, Country"
                                style="border-radius: 12px !important; height: 40px"
                                :rules="[(rule) => !!rule || '']"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="pl-6 pt-0 pb-0">
                        <v-col class="col-left-alignment">
                            <span style="font-size: 18px">Status</span>
                        </v-col>
                    </v-row>
                    <v-row dense class="px-6 pb-2">
                        <v-col>
                            <v-select
                                v-model="user.status"
                                :items="status"
                                name="status"
                                item-text="key"
                                dense
                                background-color="#E8E8E8"
                                style="border-radius: 12px !important;"
                                outlined
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <Alert class="ml-6" v-if="showErrors" :title="title" :type="alertType"></Alert>
                    <v-spacer></v-spacer>
                    <div class="my-6">
                        <v-btn class="table-create-button" @click="$emit('close')">
                            <v-icon class="icon-cancel">mdi-window-close</v-icon>
                            <span>{{ $t("cancel") }}</span>
                        </v-btn>
                        <v-btn class="table-create-button mx-6" @click="update">
                            <v-icon class="icon">mdi-plus</v-icon>
                            <span>{{ $t("save") }}</span>
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
import Alert from "@/views/Client/components/AlertDialog.vue";
import { phoneNumberFormatter } from '../../utils/luxon-formater'

export default {
    components: {
        Alert
    },
    props: ['dialog', 'user'],
    data () {
        return {
            valid: false,
            status: [
                {
                    key: 'Active',
                    value: 1,
                },
                {
                    key: 'Inactive',
                    value: 0,
                }
            ],
            showErrors: false,
            title: "Something went wrong",
            alertType: false,
            validationErrors: [],
            emailError: false,
        }
    },
    methods: {
        async update () {
            const valid = this.checkValid()
            if (!valid) {
                this.title = "Provided data is not valid: " + this.validationErrors.toString()
                this.alertType = 'error'
                this.showErrors = true
                return
            }
            const body = {
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                phone: this.user.phone.split(" ").join(""),
                email: this.user.email,
                address: this.user.address,
                zip: this.user.postal_code,
                city: this.user.city,
                state: this.user.state,
                country: this.user.country,
                addressLatitude: this.user.addressLatitude,
                addressLongitude: this.user.addressLongitude,
                username: this.user.email,
                password: "123456789",
                gender: this.user.gender,
                birthdate: this.user.birthdate,
                roleId: this.user.roleId,
                status: this.user.status,
                userTimezone: this.user.userTimezone,
                userSettingsJson: this.user.userSettingsJson,
                id: this.user.id
            };
            await this.$store.dispatch("users/updateUser", body)
                .then(res => {
                    this.$emit('updated', res)
                })
        },
        async locationDetails() {
            if (this.user.address) {
                const geo_location = await this.$store.dispatch("googleAPI/getGeoLocation",this.user.address);
                const address_components = geo_location.address_components;
                this.user.addressLatitude = geo_location.geometry.location.lat;
                this.user.addressLongitude = geo_location.geometry.location.lng;
                this.user.address = geo_location.formatted_address;
                address_components.forEach((ac) => {
                    if (ac.types.includes("administrative_area_level_1")) {
                        this.user.state = ac.long_name;
                    }
                    if (ac.types.includes("locality")) {
                        this.user.city = ac.long_name;
                    }
                    if (ac.types.includes("country")) {
                        this.user.country = ac.long_name;
                    }
                    if (ac.types.includes("postal_code")) {
                        this.user.zip = ac.long_name;
                    }
                    });
                    if (this.user.addressLatitude !== null) {
                        const tz = await this.timeZone(
                            this.user.addressLatitude,
                            this.user.addressLongitude
                        );
                    if (tz) {
                        this.user.userTimezone = tz.zoneName;
                    }
                }
            }
        },
        async timeZone(lat, lng) {
            let object = {
                lat: lat,
                lng: lng,
            };
            const time_zone = await this.$store.dispatch("googleAPI/getTimeZone", object)
            return time_zone;
        },
        checkValid () {
            this.validationErrors = []
            this.title = ""
            this.showErrors = false
            if (!phoneNumberFormatter(this.user.phone)){
                this.validationErrors.push('phone')
            }
            if (this.emailError.length > 0) {
                this.validationErrors.push('email')
            }
            if(this.user.address === "") {
                this.validationErrors.push('address')
            }
            if (this.validationErrors.length > 0) {
                return false
            } else return true
        },
    }
}
</script>
<template>
    <v-dialog
        v-model="dialog"
        width="400"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
        class="dialog-style"
    >
        <v-card>
            <v-card-title class="secondary py-1" style="height:40px;">
                <span class="dialog-title">Assign member</span>
                <v-spacer />
                <v-icon small color="primary" @click="$emit('false')">mdi-window-close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-row class="pt-9 px-2">
                    <v-col>
                        <v-list dense height="350px" class="specializations-list">
                            <v-list-item-group
                                v-model="selected"
                                active-class="primary--text"
                                multiple
                            >
                                <template v-for="(item) in users">
                                <v-list-item :key="item.id" :value="item.id">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-content>
                                            <v-list-item-title class="d-flex align-left pl-2 pt-1" v-text="item.fullName"></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-icon v-if="active" color="primary">mdi-check</v-icon>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="py-6">
                <v-spacer></v-spacer>
                <v-btn class="table-create-button" @click="$emit('false')">
                    <v-icon class="icon-cancel">mdi-window-close</v-icon>
                    <span>{{ $t('cancel') }}</span>
                </v-btn>
                <v-btn class="table-create-button mx-4" @click="assignMember()">
                    <v-icon class="icon">mdi-plus</v-icon>
                    <span>{{ $t("save") }}</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

export default {
    props: ['dialog', 'team', 'existingMembers'],
    computed: {
        ...mapState({
            relatedUsers: state => state.hospitals.medicalStaff,
        }),
        ...mapGetters({
            hospitalId: "authentication/getHospitalId",
        }),
        users () {
            return this.relatedUsers?.filter(ru => {
                ru.fullName = ru.firstName.concat(' ', ru.lastName)
                return ru
            })
        },
    },
    data () {
        return {
            memberId: null,
            selected: [],
            permanentMembers: [],
        }
    },
    async mounted () {
        await this.getStaff()
        await this.members()
        this.permanentMembers = this.selected
    },
    methods: {
        async members () {
            const memberslist = this.team.membersList
            if (memberslist !== null) {
                memberslist.forEach(tm => {
                    this.selected.push(tm.id)
                })
            }
        },
        async getStaff() {
            this.loading = true
            await this.$store.dispatch("hospitals/getRelatedMedicalStaff", this.hospitalId)
                .then(() => {
                    this.loading = false
                })
        },
        async assignMember() {
            if (this.permanentMembers === this.selected) {
                this.$emit("false");
                return;
            } else {
                const oldObject = this.permanentMembers
                const newObject = this.selected
                const assignMember = this.selected.filter(function(x) {
                    return !oldObject.includes(x)
                })
                const deassignMember = oldObject.filter(function(x) {
                    return !newObject.includes(x)
                })
                if (assignMember.length > 0) {
                    assignMember.forEach(async (memberId) => {
                        const body = {
                            medicalTeamId: this.team.id,
                            memberUserId: memberId,
                        }
                        await this.$store.dispatch("medicalteam/assignMemberToMedicalTeam", body)
                    })
                }
                if (deassignMember.length > 0) {
                    deassignMember.forEach(async (memberId) => {
                        const body = {
                            medicalTeamId: this.team.id,
                            memberUserId: memberId,
                        }
                        await this.$store.dispatch("medicalteam/deassignMemberToMedicalTeam", body)
                    })
                }
                this.$emit('membersChanged')
                this.$emit('false')
            }
        },
    }
}
</script>

<style>
  ::-webkit-scrollbar {
    width: 0px !important;
  }
</style>
<template>
  <v-dialog
    v-model="dialog"
    width="30%"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    style="background-color: blue; border-radius: 30px !important"
  >
    <v-card flat>
      <v-card-title class="secondary py-1" style="height: 40px">
        <span class="dialog-title">Medical Team</span>
        <v-spacer />
        <v-icon small color="primary" @click="$emit('close')"
          >mdi-window-close</v-icon
        >
      </v-card-title>
      <v-card-text color="primary" class="mt-5">
        <span
          >{{ method === "add" ? $t("add") : $t("edit") }}
          {{ $t("medicalTeam") }}
          <span style="color: red">(required fields *)</span></span
        >
      </v-card-text>
      <v-form v-model="valid">
        <div class="pa-10">
          <v-select
            v-model="data.hospitalId"
            label="Select Hospital *"
            :items="
              method === 'edit'
                ? hospitals.filter(
                    (hospital) => hospital.id === data.hospitalId
                  )
                : hospitals.filter(
                    (hospital) => !deniedHospitals.includes(hospital.id)
                  )
            "
            item-value="id"
            item-text="hospitalName"
            :disabled="method === 'add' ? false : true"
            :rules="hospitalRules"
            outlined
            dense
            hide-details
            filled
            style="border-radius: 12px"
            class="mb-3"
          >
          </v-select>
          <v-select
            v-model="patientId"
            label="Select Patient *"
            :items="patients"
            item-value="id"
            item-text="firstName"
            :disabled="method === 'add' ? false : true"
            :rules="patientRules"
            outlined
            dense
            hide-details
            filled
            style="border-radius: 12px"
            class="mb-3"
          >
          </v-select>
          <v-select
            v-if="method === 'add'"
            v-model="data.membersList"
            label="Select Medical Team Members"
            :items="staff"
            item-value="id"
            item-text="fullName"
            outlined
            dense
            multiple
            hide-details
            filled
            style="border-radius: 12px"
            class="mb-3"
          >
          </v-select>
          <v-text-field
            v-model="data.medicalTeamTitle"
            label="Medical Team Title *"
            :rules="medicalTeamTitleRules"
            outlined
            dense
            hide-details
            filled
            style="border-radius: 12px"
            class="mb-3"
          ></v-text-field>
          <v-textarea
            v-model="data.medicalTeamDescription"
            label="Medical Team Description *"
            :rules="medicalTeamDescriptionRules"
            outlined
            dense
            hide-details
            filled
            style="border-radius: 12px"
            class="mb-3"
            background-color="#E8E8E8"
            rounded
            rows="5"
            row-height="30"
            shaped
          ></v-textarea>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="my-6 pr-4">
            <v-btn class="table-create-button" @click="$emit('close')">
              <v-icon class="icon-cancel">mdi-window-close</v-icon>
              <span>{{ $t("cancel") }}</span>
            </v-btn>
            <v-btn
              class="table-create-button mx-4"
              :disabled="!valid"
              @click="manageMedicalTeam"
            >
              <v-icon class="icon">mdi-plus</v-icon>
              <span>{{ method === "add" ? $t("save") : $t("edit") }}</span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      valid: false,
      hospitals: [],
      medicalTeam: [],
      staff: [],
      member: -1,
      patientId: null,
    };
  },
  props: [
    "dialog",
    "userId",
    "method",
    "data",
    "deniedHospitals",
    "deniedPatients",
  ],
  computed: {
    ...mapState({
      hospitalsFromRepo: (state) => state.hospitals.hospitals,
      userDetails: (state) => state.authentication.userData,
      relatedUsers: (state) => state.hospitals.users,
      relatedPatients: (state) => state.hospitals.relatedPatients,
    }),
    patients() {
      return this.relatedPatients?.filter(
        (patient) => !this.deniedPatients.includes(patient.id)
      );
    },
    hospitalRules() {
      if (this.data.hospitalId === undefined) {
        return ["Please select a hospital!"];
      }
      return [];
    },
    patientRules() {
      if (this.patientId === null) {
        return ["Please select a hospital!"];
      }
      return [];
    },
    medicalTeamDescriptionRules() {
      if (this.data.medicalTeamDescription === undefined) {
        return ["Please fill in your Medical Team Description!"];
      }
      return [];
    },
    medicalTeamTitleRules() {
      if (this.data.medicalTeamTitle === undefined) {
        return ["Please select your Medical Team Title!"];
      }
      return [];
    },
  },
  async mounted() {
    await this.getHospitals();
    await this.getRelatedUsers();
    await this.getPatients();
  },
  methods: {
    async getHospitals() {
      await this.$store.dispatch("hospitals/getHospitals").then(() => {
        this.hospitals = this.hospitalsFromRepo;
      });
    },
    async getPatients() {
      await this.$store.dispatch(
        "hospitals/getRelatedPatients",
        this.userDetails.hospitalAssignedData.id
      );
    },
    async getRelatedUsers() {
      await this.$store
        .dispatch(
          "hospitals/getRelatedUsersForHospital",
          this.userDetails.hospitalAssignedData.id
        )
        .then(() => {
          if (this.relatedUsers !== null) {
            this.staff = this.relatedUsers
              .filter((users) => users.roleId === 3 || users.roleId === 4)
              .map(
                (users) =>
                  (users = {
                    id: users.id,
                    fullName: users.firstName + " " + users.lastName,
                  })
              );
          } else this.staff = [];
        });
    },
    async assignMemberToMedicalTeam(medicalTeamId) {
      if (this.data.membersList === undefined) {
        this.$emit("false");
        return;
      }
      this.data.membersList.forEach(async (memberId) => {
        const body = {
          medicalTeamId: medicalTeamId,
          memberUserId: memberId,
        };
        await this.$store.dispatch(
          "medicalteam/assignMemberToMedicalTeam",
          body
        );
      });
      this.$emit("false");
    },
    async manageMedicalTeam() {
      const body = {
        hospitalId: this.data.hospitalId,
        patientId: this.patientId,
        medicalTeamTitle: this.data.medicalTeamTitle,
        medicalTeamDescription: this.data.medicalTeamDescription,
        status: 1,
      };
      if (this.method === "add") {
        await this.$store
          .dispatch("medicalteam/createMedicalTeam", body)
          .then(async (res) => {
            await this.assignMemberToMedicalTeam(res.id);
          });
      } else {
        await this.$store
          .dispatch("medicalteam/editMedicalTeam", body)
          .then(() => {
            this.$emit("false");
          });
      }
    },
  },
};
</script>
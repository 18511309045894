var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticStyle:{"border-radius":"12px"},attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-start",attrs:{"cols":"8"}},[_c('h5',{staticClass:"d-flex align-left"},[_vm._v(_vm._s(_vm.$t("medicalTeam")))])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"table-search-button",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search-medical-staff'),"single-line":"","hide-details":"","filled":"","dense":"","background-color":"#E8E8E8","rounded":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{key:_vm.reload,staticStyle:{"cursor":"pointer"},attrs:{"loading":_vm.loading,"headers":_vm.headers,"headers-length":_vm.headers.length,"search":_vm.search,"items":_vm.medicalTeam,"items-per-page":_vm.medicalTeam.length,"sort-by":"correlationId","item-key":"correlationId","fixed-header":"","height":_vm.testHeight,"group-by":"[patientFullname]","single-expand":true,"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
            var group = ref.group;
            var headers = ref.headers;
            var isOpen = ref.isOpen;
            var items = ref.items;
return [_c('td',{staticStyle:{"vertical-align":"left !important","text-align":"left !important"},attrs:{"colspan":headers.length}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(group))]),_c('span',[_vm._v(" Medical Team")]),_c('v-chip',{staticClass:"ma-2 text--black",attrs:{"pill":"","x-small":"","color":"white"}},[_vm._v(" "+_vm._s(items.length)+" ")]),(_vm.roleName === 'HOSPITAL_ADMIN')?_c('v-chip',{staticClass:"ma-2 text--black",attrs:{"pill":"","small":"","color":"white"},on:{"click":function($event){return _vm.assignmemberDialog(group)}}},[_vm._v(" Assign member ")]):_vm._e()],1)]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',{on:{"click":function($event){_vm.expand(item) && _vm.deassign_dialog}}},[_c('td',{staticClass:"table-cols"},[(item.roleId === 4)?_c('img',{attrs:{"src":_vm.nurse_icon(item.status),"color":"primary","height":"24px"}}):_vm._e(),(item.roleId === 3)?_c('img',{attrs:{"src":_vm.doctor_icon(item.status),"color":"primary","height":"24px"}}):_vm._e()]),_c('td',{staticClass:"table-cols"},[_vm._v(" "+_vm._s(item.fullName)+" ")]),_c('td',{staticClass:"table-cols"},[_vm._v(" "+_vm._s(item.address)+" ")]),_c('td',{staticClass:"table-cols"},[(item.roleId === 3 && item.doctorMetadataReadDto)?_c('span',[_vm._v(" "+_vm._s(item.doctorMetadataReadDto.licenceId)+" ")]):_vm._e(),(item.roleId === 4 && item.nurseMetadata)?_c('span',[_vm._v(" "+_vm._s(item.nurseMetadata.licenceId)+" ")]):_vm._e()]),_c('td',{staticClass:"table-cols-right"},[(_vm.roleName === 'HOSPITAL_ADMIN')?_c('v-chip',{staticClass:"ma-2 table-deassign-button",attrs:{"pill":"","small":"","color":"main_red"},on:{"click":function($event){return _vm.deassignUser(item)}}},[_vm._v(" De-Assign member ")]):_vm._e()],1)])]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(_vm.expanded)?_c('DetailsCard',{attrs:{"patientId":_vm.patientId,"item":item}}):_vm._e()],1)]}},(_vm.roleName === 'HOSPITAL_ADMIN')?{key:"footer.prepend",fn:function(ref){return [_c('v-btn',{staticClass:"table-create-button",attrs:{"label":"AddDialog"},on:{"click":function($event){_vm.manageMedicalTeamDialog = true}}},[_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t("addNewMedicalTeam")))])],1)]}}:null],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.deassign_dialog),callback:function ($$v) {_vm.deassign_dialog=$$v},expression:"deassign_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"primary--text"},[_vm._v("Are you sure?")])]),_c('v-card-text',[_vm._v(" Do you really want to de-assign this member for this medical team? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"main_black","text":""},on:{"click":function($event){_vm.deassign_dialog = false}}},[_vm._v(" Disagree ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.deassignMember(_vm.memberToDeassign)}}},[_vm._v(" Agree ")])],1)],1)],1),(_vm.dialog)?_c('add-staff-dialog',{attrs:{"dialog":_vm.dialog},on:{"false":function($event){_vm.dialog = false}}}):_vm._e(),(_vm.manageMedicalTeamDialog)?_c('ManageMedicalTeamDialog',{attrs:{"dialog":_vm.manageMedicalTeamDialog,"deniedHospitals":_vm.deniedHospitals,"deniedPatients":_vm.deniedPatients,"method":_vm.medicalTeamMethod,"data":_vm.medicalTeamData},on:{"false":function($event){return _vm.closeDialog()},"close":function($event){_vm.manageMedicalTeamDialog = false}}}):_vm._e(),(_vm.assign_dialog)?_c('AssignMedicalMember',{attrs:{"dialog":_vm.assign_dialog,"team":_vm.mteam},on:{"false":function($event){return _vm.closeDialog()}}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
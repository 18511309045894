<template>
    <v-row class="mx-2 my-2">
        <v-col cols="12" xl="4" lg="4" md="12" sm="12">
            <v-card style="border-radius: 10px" width="100%" :height="cardHeight">
                <v-card-title class="secondary py-1" style="height:40px;">
                    <span class="dialog-title">Staff Details</span>
                    <v-spacer />
                    <v-btn x-small elevation="0" color="secondary" @click="editstaffdetails">
                        <v-icon small color="primary">mdi-pencil</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="pt-5">
                    <v-row dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Name:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ item.fullName }}</span>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="table-cols" cols="12" xl="4" lg="4" md="8" sm="8">
                            Address:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="8" lg="8" md="8" sm="8">
                            <span style="font-weight: 600;" class="main_black--text">{{ item.address }}, {{ item.zip }}, {{ item.city }}, {{ item.countryName }}</span>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Email:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ item.email }}</span>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Phone:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ item.phone }}</span>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Birthdate:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ item.birthdateAsString }}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="12" sm="12">
            <v-card :height="cardHeight" style="border-radius: 10px !important">
                <v-card-title class="secondary py-1" style="height:40px;">
                    <span class="dialog-title">Work Details</span>
                    <v-spacer />
                </v-card-title>
                <v-card-text class="pt-5">
                    <v-row dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Hospital:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ hospitalName }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="itemWorkDetails" dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Licence:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ itemWorkDetails.licenceId }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="itemSpecializations" dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Specializations:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                        </v-col>
                    </v-row>
                    <v-row v-if="itemSpecializations" dense>
                        <div class="specialization-tabs">
                            <v-chip v-for="item in itemSpecializations" class="mx-1 my-1" color="primary" :key="item.spetializationId">
                                {{ item.spetializationDesc }}
                            </v-chip>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col v-if="patientId !== undefined" cols="12" xl="4" lg="4" md="12" sm="12">
            <v-card style="border-radius: 10px !important" :loading="patientloading" :height="cardHeight">
                <v-card-title class="secondary py-1" style="height:40px;">
                    <span class="dialog-title">Patient Details</span>
                    <v-spacer />
                </v-card-title>
                <v-card-text class="pt-5">
                    <v-row v-if="patientDetails" dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Patient Name:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ patientDetails.firstName }} {{ patientDetails.lastName }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="patientDetails" dense>
                        <v-col class="table-cols" cols="12" xl="3" lg="3" md="3" sm="3">
                            Patient Email:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="9" lg="9" md="9" sm="9">
                            <span style="font-weight: 600;" class="main_black--text">{{ patientDetails.email }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="itemWorkDetails" dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Hospital:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ hospitalName }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="patientMetaData" dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Medical Registration Number:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ patientMetaData.medicalRegistrationNumber }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="patientMetaData" dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Alcool consumtion:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ patientMetaData.alcoolConsumtion }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="patientMetaData" dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Smoker:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ patientMetaData.isCigaretteSmoker ? 'Yes' : 'No' }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="patientMetaData" dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Height:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                           <span style="font-weight: 600;" class="main_black--text">{{ patientMetaData.heightInCm }} cm</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="patientMetaData" dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Weight:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ patientMetaData.weightInKg }} kg</span>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col v-else cols="12" xl="4" lg="4" md="12" sm="12">
            <v-card style="border-radius: 10px !important" :height="cardHeight">
                <v-card-title class="secondary py-1" style="height:40px;">
                    <span class="dialog-title">Hospital Details</span>
                    <v-spacer />
                </v-card-title>
                <v-card-text class="pt-5">
                    <v-row dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Hospital:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ hospitalData.hospitalName }}</span>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Address:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ hospitalData.address }}, {{ hospitalData.zip }}, {{ hospitalData.city }}, {{ hospitalData.country }} </span>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Contact Person:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ hospitalData.contactPerson }}</span>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Contact Phone:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ hospitalData.contactPhone }}</span>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                            Contact Email:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                            <span style="font-weight: 600;" class="main_black--text">{{ hospitalData.contactEmail }}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <MedicalStaffDetailsDialog v-if="editmedicalstaffdialog" :dialog="editmedicalstaffdialog" :user="edituser" @close="editmedicalstaffdialog = false" @updated="updatedstaffdetails"></MedicalStaffDetailsDialog>
            <ResponseDialog v-if="responseStatus" :status.sync="responseStatus" :title="responseTitle" :color="responseColor"></ResponseDialog>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from 'vuex'
import MedicalStaffDetailsDialog from '@/views/dialogs/MedicalStaffDetailsDialog'
import ResponseDialog from "@/views/Client/components/Alert.vue"


export default {
    components: {
        MedicalStaffDetailsDialog,
        ResponseDialog
    },
    props: ['item', 'patientId', 'type'],
    data () {
        return {
            patientloading: false,
            editmedicalstaffdialog: false,
            edituser: null,
            responseStatus: false,
            responseTitle: "",
            responseColor: "",
        }
    },
    computed: {
        ...mapState({
            detailedUser: state => state.users.detailedUser,
            doctormetadata: state => state.doctormetadata.metaData,
            hospitalData: state => state.hospitals.hospitalData,
            nursemetadata: state => state.nursemetadata.nursemetadata
        }),
        cardHeight () {
            return 310
        },
        itemWorkDetails () {
            if (this.type === 'mstaff') {
                if (this.item.roleId === 3) {
                    return this.doctormetadata
                } else return this.nursemetadata
            } else {
                if (this.item.roleId === 3) {
                    return this.item.doctorMetadataReadDto
                } else return this.item.nurseMetadata
            }
        },
        hospitalName () {
            if (this.type === 'mstaff') {
                if (this.item.roleId === 3) {
                    if (this.itemWorkDetails?.hospitalsAssignedData.length > 0) {
                        return this.itemWorkDetails?.hospitalsAssignedData[0]?.hospitalName
                    } else return ''
                } else return this.hospitalData.hospitalName
            } else return ''
        },
        itemSpecializations () {
            return this.itemWorkDetails?.spetializationData
        },
        patientDetails () {
            return this.detailedUser?.userData
        },
        patientMetaData () {
            return this.detailedUser?.userData?.patientMetadata
        },
    },
    watch: {
        patientId: {
			immediate: true,
			deep: true,
			async handler (newVal) {
                if (newVal !== undefined) {
                    await this.getPatientDetails(newVal)
                }
			},
		},
        type: {
            immediate: true,
            deep: true,
            async handler (val) {
                if (val === 'mstaff') {
                    if (this.item.roleId === 3) {
                        await this.getDoctorMetaData(this.item.id)
                    } else await this.getNurseMetaData(this.item.id)
                }
            }
        }
    },
    methods: {
        async getPatientDetails (id) {
            this.patientloading = true
            await this.$store.dispatch("users/getUserById", id)
            this.patientloading = false
        },
        async getDoctorMetaData (id) {
            await this.$store.dispatch("doctormetadata/getMetaData", id)
        },
        async getNurseMetaData (id) {
            await this.$store.dispatch("nursemetadata/getMetaData", id)
        },
        editstaffdetails () {
            this.editmedicalstaffdialog = true
            this.edituser = this.item
        },
        updatedstaffdetails (res) {
            this.editmedicalstaffdialog = false
            this.edituser = null
            if (res.resFlag) {
                 this.responseColor = 'main_green'
                this.responseTitle = res.msg
                this.responseStatus = true
            } else {
                 this.responseColor = 'main_red'
                this.responseTitle = res.msg
                this.responseStatus = true
            }
        },
    },
}
</script>
<template>
  <div>
    <v-row class="mx-2">
      <v-col cols="12">
        <v-card flat style="border-radius: 12px">
          <v-card-title>
            <v-row>
              <v-col class="d-flex align-center justify-start" cols="8"
                ><h5 class="d-flex align-left">{{ $t("medicalTeam") }}</h5>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search-medical-staff')"
                  single-line
                  hide-details
                  filled
                  dense
                  background-color="#E8E8E8"
                  rounded
                  class="table-search-button"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :loading="loading"
            :headers="headers"
            :headers-length="headers.length"
            :search="search"
            :items="medicalTeam"
            :items-per-page="medicalTeam.length"
            sort-by="correlationId"
            item-key="correlationId"
            fixed-header
            style="cursor: pointer"
            :height="testHeight"
            group-by="[patientFullname]"
            :key="reload"
            :single-expand="true"
            :expanded.sync="expanded"
          >
            <template
              v-slot:[`group.header`]="{
                group,
                headers,

                isOpen,
                items,
              }"
            >
              <td
                style="
                  vertical-align: left !important;
                  text-align: left !important;
                "
                :colspan="headers.length"
              >
                <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
                <span style="font-weight: bold">{{ group }}</span
                ><span> Medical Team</span>
                <v-chip pill x-small color="white" class="ma-2 text--black">
                  {{ items.length }}
                </v-chip>

                <v-chip
                  v-if="roleName === 'HOSPITAL_ADMIN'"
                  @click="assignmemberDialog(group)"
                  pill
                  small
                  color="white"
                  class="ma-2 text--black"
                >
                  Assign member
                </v-chip>
              </td>
            </template>
            <template v-slot:item="{ item }">
              <tr @click="expand(item) && deassign_dialog">
                <td class="table-cols">
                  <img
                    :src="nurse_icon(item.status)"
                    v-if="item.roleId === 4"
                    color="primary"
                    height="24px"
                  />
                  <img
                    :src="doctor_icon(item.status)"
                    v-if="item.roleId === 3"
                    color="primary"
                    height="24px"
                  />
                </td>
                <td class="table-cols">
                  {{ item.fullName }}
                </td>
                <td class="table-cols">
                  {{ item.address }}
                </td>
                <td class="table-cols">
                  <span v-if="item.roleId === 3 && item.doctorMetadataReadDto">
                    {{ item.doctorMetadataReadDto.licenceId }}
                  </span>
                  <span v-if="item.roleId === 4 && item.nurseMetadata">
                    {{ item.nurseMetadata.licenceId }}
                  </span>
                </td>
                <td class="table-cols-right">
                  <v-chip
                    v-if="roleName === 'HOSPITAL_ADMIN'"
                    @click="deassignUser(item)"
                    pill
                    small
                    color="main_red"
                    class="ma-2 table-deassign-button"
                  >
                    De-Assign member
                  </v-chip>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <DetailsCard
                  v-if="expanded"
                  :patientId="patientId"
                  :item="item"
                ></DetailsCard>
              </td>
            </template>
            <template
              v-if="roleName === 'HOSPITAL_ADMIN'"
              v-slot:[`footer.prepend`]="{}"
            >
              <v-btn
                class="table-create-button"
                label="AddDialog"
                @click="manageMedicalTeamDialog = true"
              >
                <v-icon class="icon">mdi-plus</v-icon>
                <span>{{ $t("addNewMedicalTeam") }}</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="deassign_dialog" persistent max-width="290">
          <v-card>
            <v-card-title>
              <span class="primary--text">Are you sure?</span>
            </v-card-title>
            <v-card-text>
              Do you really want to de-assign this member for this medical team?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="main_black" text @click="deassign_dialog = false">
                Disagree
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="deassignMember(memberToDeassign)"
              >
                Agree
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <add-staff-dialog
          v-if="dialog"
          :dialog="dialog"
          @false="dialog = false"
        />
        <ManageMedicalTeamDialog
          v-if="manageMedicalTeamDialog"
          :dialog="manageMedicalTeamDialog"
          :deniedHospitals="deniedHospitals"
          :deniedPatients="deniedPatients"
          :method="medicalTeamMethod"
          :data="medicalTeamData"
          @false="closeDialog()"
          @close="manageMedicalTeamDialog = false"
        />
        <AssignMedicalMember
          v-if="assign_dialog"
          :dialog="assign_dialog"
          :team="mteam"
          @false="closeDialog()"
        ></AssignMedicalMember>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import account from "@/assets/mr_account.svg";
import AssignMedicalMember from "@/views/dialogs/AssignMedicalMember.vue";
import DetailsCard from "@/views/Client/components/DetailsCard.vue";
import ManageMedicalTeamDialog from "@/views/dialogs/ManageMedicalTeamDialog.vue";
import {
  doctor_black,
  doctor_secondary,
  nurse_black,
  nurse_secondary,
} from "@/assets";

export default {
  components: {
    AssignMedicalMember,
    DetailsCard,
    ManageMedicalTeamDialog,
  },
  data() {
    return {
      expanded: [],
      account: account,
      medicalTeams: [],
      tempTeam: [],
      loading: true,
      search: null,
      dialog: false,
      manageMedicalTeamDialog: false,
      medicalTeamMethod: "add",
      deniedHospitals: [],
      medicalTeamData: {},
      mteam: null,
      assign_dialog: false,
      reload: 0,
      deassign_dialog: false,
      memberToDeassign: null,
      patientId: null,
      doctor_black: doctor_black,
      doctor_secondary: doctor_secondary,
      nurse_black: nurse_black,
      nurse_secondary: nurse_secondary,
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.authentication.userData,
      medicalTeamFromRepo: (state) => state.medicalteam.medicalTeam,
    }),
    ...mapGetters({
      roleName: "authentication/getRole",
      hospitalId: "authentication/getHospitalId",
      myMedicalTeam: "authentication/getMyMedicalTeam",
      pageHeight: "pageHeight",
    }),
    testHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return window.innerHeight - 320
        case 'sm': return window.innerHeight - 300
        case 'md': return window.innerHeight - 300
        case 'lg': return window.innerHeight - 280
        case 'xl': return window.innerHeight - 280
        default: return 0
      }
    },
    medicalTeam() {
      var test = [];
      var i = 0;
      this.medicalTeamFromRepo.forEach((element) => {
        element.membersList.forEach((c) => {
          i++;
          c.patientFullname = element.patientFullname;
          c["fullName"] = c.firstName.concat(" ", c.lastName);
          c["statusDescription"] = c.status === 1 ? "Active" : "Inactive";
          c["sex"] = c.gender === "M" ? "Male" : "Female";
          c["role"] = c.roleId === 3 ? "Doctor" : "Nurse";
          c["correlationId"] = i;
          c["medicalTeamId"] = element.id;
          c["medicalTeamTitle"] = element.medicalTeamTitle;
          test.push(c);
        });
      });
      return test;
    },
    deniedPatients() {
      let d = [];
      this.medicalTeamFromRepo.forEach((element) => {
        d.push(element.patientId);
      });
      return d;
    },
    headers() {
      var headers = [];
      headers = [
        {
          text: this.$t("header-status"),
          value: "statusDescription",
        },
        {
          text: this.$t("header-full-name"),
          value: "fullName",
        },
        {
          text: this.$t("header-address"),
          value: "address",
        },
        {
          text: this.$t("header-medical-licence-id"),
          value: "licence",
        },
        {
          text: this.$t("header-actions"),
          value: "actions",
          class: "text-end",
        },
      ];
      return headers;
    },
  },
  methods: {
    async expand(item) {
      if (this.deassign_dialog) {
        return;
      }
      const medicalteam = this.medicalTeamFromRepo.filter((c) => {
        return c.id === item.medicalTeamId;
      });
      this.patientId = medicalteam[0].patientId;
      if (item === this.expanded[0]) this.expanded = [];
      else this.expanded = [item];
    },
    async getUsers() {
      if (this.userData.roleData.roleName === "NURSE") {
        await this.$store
          .dispatch("medicalteam/getMedicalTeamForNurse", this.userData.id)
          .then((res) => {
            this.medicalTeams = res.medicalTeamsData;
            this.tempTeam = this.medicalTeams.map((device) => ({
              ...device,
              tempMember: device.membersList,
            }));
            this.loading = false;
          });
      } else if (this.userData.roleData.roleName === "HOSPITAL_ADMIN") {
        await this.$store.dispatch(
          "medicalteam/getMedicalTeamForHospitalAdmin",
          this.userData.id
        );
        this.loading = false;
      } else {
        await this.$store
          .dispatch("medicalteam/getMedicalTeamForDoctor", this.userData.id)
          .then((res) => {
            this.medicalTeams = res.medicalTeamsData;
            this.tempTeam = this.medicalTeams.map((device) => ({
              ...device,
              tempMember: device.membersList,
            }));
            this.loading = false;
          });
      }
    },
    async deassignUser(item) {
      this.deassign_dialog = true;
      this.memberToDeassign = item;
    },
    async deassignMember(item) {
      const body = {
        medicalTeamId: item.medicalTeamId,
        memberUserId: item.id,
      };
      await this.$store
        .dispatch("medicalteam/deassignMemberToMedicalTeam", body)
        .then(() => {
          this.getUsers();
          this.deassign_dialog = false;
        });
    },
    assignmemberDialog(item) {
      const team = this.medicalTeamFromRepo.filter((m) => {
        return m.patientFullname === item;
      });
      this.mteam = team[0];
      if (this.mteam.length !== null) {
        this.assign_dialog = true;
      }
    },
    closeDialog() {
      this.manageMedicalTeamDialog = false;
      this.assign_dialog = false;
      this.reload++;
      this.getUsers();
    },
    doctor_icon(item) {
      switch (item) {
        case 1:
          return this.doctor_black;
        default:
          return this.doctor_secondary;
      }
    },
    nurse_icon(item) {
      switch (item) {
        case 1:
          return this.nurse_black;
        default:
          return this.nurse_secondary;
      }
    },
  },
  async mounted() {
    await this.getUsers();
  },
};
</script>
<style scoped>
</style>